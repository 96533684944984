<template>
  <div v-if="cases.id" class="py-4 bg-white sm:px-6 lg:px-8">
    <dl>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Start Date</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">
            {{
              DateTime.fromISO(cases.created_at).toFormat("MMMM dd, yyyy")
            }}</span
          >
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">ID</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">{{ cases.id }}</span>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
        <dt class="text-sm font-medium text-gray-500">Client</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">
            <router-link
              :to="{ name: 'client', params: { id: cases.user.id } }"
              class="flex-shrink-0 group block"
            >
              <div class="flex items-center">
                <Avatar
                  :user="cases.user"
                  :className="['inline-block', 'h-9', 'w-9', 'rounded-full']"
                />
                <div class="ml-3">
                  <p
                    class="
                      text-sm text-gray-800
                      group-hover:text-gray-900
                      font-medium
                    "
                  >
                    {{ cases.user.first_name }}
                  </p>
                  <p
                    class="
                      text-xs
                      font-medium
                      text-gray-700
                      group-hover:text-gray-900
                    "
                  >
                    View profile ->
                  </p>
                </div>
              </div>
            </router-link>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
        <dt class="text-sm font-medium text-gray-500">Stage</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span
            :class="`
              inline-flex
              items-center
              px-2.5
              py-0.5
              rounded-full
              text-xs
              font-medium
              bg-${cases.info.stage.color}-100
              text-${cases.info.stage.color}-800
            `"
          >
            {{ cases.info.stage.name }}
          </span>
        </dd>
      </div>
      <div
        class="
          py-4
          sm:py-5
          sm:grid sm:grid-cols-3
          sm:gap-4
          sm:border-b sm:border-gray-200
        "
      >
        
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Address</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">{{ cases.info.address.street_address }}</span>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Type</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">{{ cases.info.app_type.name }}</span>
        </dd>
      </div>
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Price</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">£ {{ cases.info.amount }}</span>
        </dd>
      </div>
      <div
        class="
          py-4
          sm:py-5
          sm:grid sm:grid-cols-3
          sm:gap-4
          md:border-b md:border-t
          sm:border-gray-200
        "
      >
        <dt class="text-sm font-medium text-gray-500">Bank</dt>
        <dd
          class="
            mt-1
            flex
            text-sm text-gray-900
            sm:mt-0
            sm:col-span-2
            flex-wrap
            lg:gap-8
            md:gap-6
            gap-4
          "
        >
          <span
            v-if="cases.info.is_mortgage"
            class="relative z-0 inline-flex shadow-sm rounded-md"
          >
            <span
              class="
                relative
                inline-flex
                items-center
                px-2
                py-2
                rounded-l-md
                border border-gray-300
                bg-white
              "
            >
              <input
                readonly
                disabled
                checked
                id="mortgage"
                type="checkbox"
                name="select-all"
                class="h-4 w-4 text-gray-400 border-gray-300 rounded"
              />
            </span>
            <label
              name="message-type"
              class="
                -ml-px
                block
                w-full
                pl-3
                pr-9
                py-2
                rounded-l-none rounded-r-md
                border border-gray-300
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:z-10
                focus:outline-none
                focus:ring-1 focus:ring-blue-500
                focus:border-blue-500
              "
              for="mortgage"
              >There is a mortgage on this property</label
            >
          </span>
          <span class="relative z-0 inline-flex shadow-sm rounded-md">
            <span
              class="
                relative
                inline-flex
                items-center
                px-2
                py-2
                rounded-l-md
                border border-gray-300
                bg-white
              "
            >
              <input
                readonly
                disabled
                checked
                id="leasehold"
                type="checkbox"
                name="select-all"
                class="h-4 w-4 text-gray-400 border-gray-300 rounded"
              />
            </span>
            <label
              name="message-type"
              class="
                -ml-px
                block
                w-full
                pl-3
                pr-9
                py-2
                rounded-l-none rounded-r-md
                border border-gray-300
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:z-10
                focus:outline-none
                focus:ring-1 focus:ring-blue-500
                focus:border-blue-500
              "
              for="leasehold"
              >{{ cases.info.property_type?.name }}</label
            >
          </span>
        </dd>
      </div>
      <div
        class="
          py-4
          sm:py-5
          sm:grid sm:grid-cols-3
          sm:gap-4
          md:border-b
          sm:border-gray-200
        "
      >
        <dt class="text-sm font-medium text-gray-500">Documents</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <ul
            class="border border-gray-200 rounded-md divide-y divide-gray-200"
          >
            <li
              class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div class="w-0 flex-1 flex items-center">
                <DocumentTextIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-2 flex-1 w-0 truncate">
                  LegalFormTA10FittingsandContentsForm-0.pdf
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <a
                  href="#"
                  class="font-medium text-blue-600 hover:text-blue-500"
                >
                  Download
                </a>
              </div>
            </li>
            <li
              class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div class="w-0 flex-1 flex items-center">
                <DocumentTextIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-2 flex-1 w-0 truncate">
                  DocumentClientInformationQuestionnaireSale-2.pdf
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <a
                  href="#"
                  class="font-medium text-blue-600 hover:text-blue-500"
                >
                  Download
                </a>
              </div>
            </li>
            <li
              class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div class="w-0 flex-1 flex items-center">
                <DocumentTextIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span class="ml-2 flex-1 w-0 truncate">
                  To_Client_-_Enclose_Quote.pdf
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <a
                  href="#"
                  class="font-medium text-blue-600 hover:text-blue-500"
                >
                  Download
                </a>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
    <!-- Comments-->
    <section aria-labelledby="notes-title" class="mt-5">
      <div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
        <div class="divide-y divide-gray-200">
          <div class="px-4 py-5 sm:px-6">
            <h2 id="notes-title" class="text-lg font-medium text-gray-900">
              Notes
            </h2>
          </div>
          <div ref="container" class="px-4 py-6 sm:px-6 max-h-80 overflow-auto">
            <transition-group class="space-y-8" name="list" tag="ul">
              <li v-for="note in cases.case_notes" :key="note.id">
                <div class="flex space-x-3">
                  <div class="flex-shrink-0">
                    <Avatar
                      :user="note.user"
                      :className="['h-8', 'w-8', 'rounded-full']"
                    />
                  </div>
                  <div>
                    <div class="text-sm">
                      <a href="#" class="font-medium text-gray-900"
                        >{{ note.user?.first_name }}
                        {{ note.user?.last_name }}</a
                      >
                    </div>
                    <div class="mt-1 text-sm text-gray-700">
                      <p>{{ note.note }}</p>
                    </div>
                    <div class="mt-2 text-sm space-x-2">
                      <span class="text-gray-500 font-medium">{{
                        DateTime.fromISO(note.created_at).toLocaleString(
                          DateTime.DATETIME_MED
                        )
                      }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </transition-group>
            <p v-if="!cases.case_notes.length > 0">
              There are no notes has been added yet
            </p>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-6 sm:px-6">
          <div class="flex space-x-3">
            <div class="flex-shrink-0">
              <Avatar
                :user="user"
                :className="['h-10', 'w-10', 'rounded-full']"
              />
            </div>
            <div class="min-w-0 flex-1">
              <form @submit.prevent="handleSubmit">
                <div>
                  <label for="comment" class="sr-only">Note</label>
                  <textarea
                    required
                    v-model="note"
                    id="note"
                    name="note"
                    rows="3"
                    class="
                      shadow-sm
                      block
                      w-full
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                    placeholder="Add a note"
                  />
                </div>
                <div class="mt-3 flex items-center justify-between">
                  <span></span>
                  <button
                    type="submit"
                    class="
                      inline-flex
                      items-center
                      justify-center
                      px-4
                      py-2
                      border border-transparent
                      text-sm
                      font-medium
                      rounded-md
                      shadow-sm
                      text-white
                      bg-blue-600
                      hover:bg-blue-700
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    "
                  >
                    Comment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { DocumentTextIcon } from "@heroicons/vue/solid";
import { DateTime } from "luxon";
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import Avatar from "@/components/Avatar";
const profile = {
  name: "Ricardo Cooper",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  coverImageUrl:
    "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
  about:
    "\n        <p>\n          Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.\n        </p>\n        <p>\n          Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus praesent dictum risus suspendisse.\n        </p>\n      ",
  fields: {
    Phone: "(555) 123-4567",
    Email: "ricardocooper@example.com",
    Title: "Senior Front-End Developer",
    Team: "Product Development",
    Location: "San Francisco",
    Sits: "Oasis, 4th floor",
    Salary: "$145,000",
    Birthday: "June 8, 1990",
  },
};

export default {
  components: {
    DocumentTextIcon,
    Avatar,
  },
  setup() {
    const store = useStore();
    const note = ref("");
    const container = ref(null);

    const scrollToBottom = () => {
      console.log(container.value.scrollHeight);
      if (container.value.scrollHeight >= 320) {
        container.value.scrollTop = container.value.scrollHeight;
      }
    };

    onMounted(() => scrollToBottom());

    const handleSubmit = async () => {
      await store.dispatch("cases/createCaseNote", {
        note: note.value,
        caseId: store.state.cases.case.id,
      });
      scrollToBottom();
      note.value = "";
    };

    return {
      profile,
      DateTime,
      handleSubmit,
      note,
      container,
      cases: computed(() => store.state.cases.case),
      user: computed(() => store.getters["user/user"]),
    };
  },
};
</script>

<style scoped>
.list-enter-active {
  transition: all 0.5s ease;
}
.list-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
</style>